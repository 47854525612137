import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "components"
    }}>{`Components`}</h1>
    <p>{`The MDX core library accepts a string and exports a JSX string.
It uses a `}<a parentName="p" {...{
        "href": "/blog/custom-pragma"
      }}>{`custom pragma`}</a>{` which customizes
the rendering of elements in Markdown and JSX.`}</p>
    {
      /* TODO: rewrite to update for v1. */
    }
    <h2 {...{
      "id": "tranpilation"
    }}>{`Tranpilation`}</h2>
    <p>{`Consider the following MDX:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`import MyComponent from './my-component'

export const author = 'Fred Flintstone'

# Title

<MyComponent />

Lorem ipsum dolor sit amet.
`}</code></pre>
    <p>{`MDX core turns that text into roughly the following JSX to be consumed by your
app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import MyComponent from './my-component'

export const author = 'Fred Flintstone'

const layoutProps = { author }
export default MDXContent({ components, ...props }) => (
  <wrapper {...props} {...layoutProps}>
    <h1>Title</h1>
    <MyComponent />
    <p>Lorem ipsum dolor sit amet.</p>
  </wrapper>
)
MDXContent.isMDXComponent = true
`}</code></pre>
    <p>{`If the component mapping contains a `}<inlineCode parentName="p">{`p`}</inlineCode>{` key, that will be used for
`}<inlineCode parentName="p">{`Lorem ipsum dolor sit amet.`}</inlineCode>{`.
Otherwise a standard `}<inlineCode parentName="p">{`p`}</inlineCode>{` tag is rendered (`}<inlineCode parentName="p">{`<p>Lorem ipsum dolor sit amet.</p>`}</inlineCode>{`).
This is what allows you to pull in existing components to style your MDX
documents via the `}<a parentName="p" {...{
        "href": "#mdxprovider"
      }}>{`MDXProvider`}</a></p>
    <h3 {...{
      "id": "layout-props"
    }}>{`Layout props`}</h3>
    <p>{`You’ll also notice that `}<inlineCode parentName="p">{`layoutProps`}</inlineCode>{` is created based on your exports
and then passed to the wrapper.  This allows for the wrapper to use
those props automatically for handling things like adding an author
bio to the wrapped document.`}</p>
    <h2 {...{
      "id": "ismdxcomponent"
    }}><inlineCode parentName="h2">{`isMDXComponent`}</inlineCode></h2>
    <p>{`If you need to check whether a React component has been created by MDX,
all MDX components have a static property `}<inlineCode parentName="p">{`isMDXComponent`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import ChangeLog from '../changelog.mdx'

export default () => (
  <div>
    <p>Component type: {ChangeLog.isMDXComponent ? 'MDX' : 'Regular'}</p>
    <ChangeLog />
  </div>
)
`}</code></pre>
    <h2 {...{
      "id": "mdxprovider"
    }}>{`MDXProvider`}</h2>
    <h3 {...{
      "id": "caveats"
    }}>{`Caveats`}</h3>
    <p>{`Because MDXProvider uses React Context directly, it is affected by
the same caveats.  It is therefore important that you do not declare
your components mapping inline in the JSX.  Doing so will trigger a rerender
of your entire MDX page with every render cycle.  Not only is this bad for
performance, but it can cause unwanted side affects, like breaking in-page
browser navigation.`}</p>
    <p>{`Avoid this by following declaring your mapping as a constant.`}</p>
    <h4 {...{
      "id": "updating-the-mapping-object-during-application-runtime"
    }}>{`Updating the mapping object during application runtime`}</h4>
    <p>{`If you need to change the mapping during runtime, declare it on the componentʼs state object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import { Heading, Text, Pre, Code, Table } from './components'

export default class Layout extends React.Component {
  state = {
    h1: Heading.H1,
    h2: Heading.H2,
    // ...
    p: Text,
    code: Pre,
    inlineCode: Code
  }

  render() {
    return (
      <MDXProvider components={this.state}>
        <main {...this.props} />
      </MDXProvider>
    )
  }
}
`}</code></pre>
    <p>{`You can now use the `}<inlineCode parentName="p">{`setState`}</inlineCode>{` function to update the mapping object and be assured that it wonʼt trigger unnecessary renders.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      